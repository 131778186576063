var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "order-card-container"
  }, [[_c('b-card', {
    style: _vm.activatePaymentKey ? 'margin-top: 10px;' : '',
    attrs: {
      "header-class": "price-header",
      "header": "PRICE DETAILS"
    }
  }, [_vm.$store.state['checkout'].checkoutLoading == true ? _vm._l(8, function (i) {
    return _c('b-skeleton', {
      key: i,
      staticClass: "mb-3",
      style: "width: ".concat(Math.floor(Math.random() * 31) + 50, "%;"),
      attrs: {
        "animation": "wave"
      }
    });
  }) : _vm._e(), _vm.$store.state['checkout'].checkoutLoading == false ? _c('blockquote', {
    staticClass: "blockquote mb-0"
  }, [_c('b-row', {
    staticClass: "mb-2"
  }, [_c('b-col', [_c('b-card-text', {
    staticClass: "price-details"
  }, [_vm._v("Subtotal "), _c('br'), _vm._v("(" + _vm._s(_vm.checkoutData.items.length || _vm.checkoutData.listPrice) + " items)")])], 1), _c('b-col', [_c('b-card-text', {
    staticClass: "float-right price-details"
  }, [_vm._v(" ₹ " + _vm._s(_vm.checkoutData.totalPriceBeforeTax))])], 1)], 1), _c('b-row', {
    staticClass: "mb-2"
  }, [_c('b-col', [_c('b-card-text', {
    staticClass: "price-details color-red"
  }, [_vm._v("Discount: ")])], 1), _c('b-col', [_c('b-card-text', {
    staticClass: "float-right price-details color-red"
  }, [_vm._v(" - ₹ " + _vm._s(_vm.checkoutData.totalDiscount) + " ")])], 1)], 1), _c('b-row', {
    staticClass: "mb-2"
  }, [_c('b-col', [_c('b-card-text', {
    staticClass: "price-details"
  }, [_vm._v("Tax:")])], 1), _c('b-col', [_c('b-card-text', {
    staticClass: "float-right price-details"
  }, [_vm._v(" ₹ " + _vm._s(_vm.checkoutData.totalTaxes) + " ")])], 1)], 1), !_vm.checkoutData.totalDeliveryCharge == 0 ? _c('b-row', {
    staticClass: "mb-3"
  }, [_c('b-col', [_c('b-card-text', {
    staticClass: "price-details"
  }, [_vm._v("Shipping: ")])], 1), _c('b-col', [_c('b-card-text', {
    staticClass: "float-right price-details"
  }, [_vm._v(" ₹ " + _vm._s(_vm.checkoutData.totalDeliveryCharge))])], 1)], 1) : _vm._e(), _c('b-row', {
    staticClass: "mb-3"
  }, [_c('b-col', [_c('b-card-text', {
    staticClass: "price-total-amount"
  }, [_vm._v("Total Amount ")])], 1), _c('b-col', [_c('b-card-text', {
    staticClass: "float-right price-total-amount"
  }, [_vm._v(" ₹ " + _vm._s(_vm.checkoutData.totalPrice))])], 1)], 1)], 1) : _vm._e(), _vm.$options.components['address-no-address'] && _vm.$store.state['checkout'].checkoutLoading === 'errorAddress' ? [_c('address-no-address')] : _vm._e()], 2)]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }